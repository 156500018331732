import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import Link from "gatsby-link";
import Content, { HTMLContent } from "../components/Content";
import CatCategory from "../components/CatCategory";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

export const ArtlabPostTemplate = ({
  content,
  contentComponent,
  description,
  // tags,
  title,
  p1,
  p2,
  credits,
  helmet,
  catCategories
}) => {
  const PostContent = contentComponent || Content;
  const cats = catCategories,
    categoriesArrayOnTrue = Object.keys(cats || {}).filter(key => cats[key]);
  return (
    <section className="ArtlabPost">
      {JSON.stringify(description)}
      {helmet || ""}
      <div className="columns firstContainer">
        <div className="column withTitle">
          <h1 className="title">{title}</h1>
          {categoriesArrayOnTrue &&
          Array.isArray(categoriesArrayOnTrue) &&
          categoriesArrayOnTrue.length > 0 ? (
            <CatCategory
              horizontal
              white={false}
              categoryKey={categoriesArrayOnTrue[0]}
            />
          ) : null}
          <p style={{ display: "none" }}>{description}</p>
          <div className="p1P2Credits">
            {p1 && (
              <div className="p1">
                <PostContent content={p1} />
              </div>
            )}
            {p2 && (
              <div className="p2">
                <PostContent content={p2} />
              </div>
            )}
          </div>
        </div>
        {credits && (
          <div className="workCredits">
            <h4>CREDITS</h4>
            <pre>
              <PostContent content={credits} />
            </pre>
          </div>
        )}
      </div>
      <div className="columns secondContainer">
        <PostContent content={content} />
      </div>
    </section>
  );
};

ArtlabPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  p1: PropTypes.string,
  p2: PropTypes.string,
  credits: PropTypes.string
  // helmet: PropTypes.instanceOf(Helmet)
};

const ArtlabPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ArtlabPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet
            title={`${post.frontmatter.title} | Works`}
            meta={[
              { name: "description", content: post.frontmatter.description }
            ]}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        p1={post.frontmatter.p1}
        p2={post.frontmatter.p2}
        credits={post.frontmatter.credits}
        catCategories={post.frontmatter.cat_categories}
      />
    </Layout>
  );
};

ArtlabPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ArtlabPost;

export const pageQuery = graphql`
  query ArtlabPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        templateKey
        date(formatString: "MMMM DD, YYYY")
        cat_categories {
          advertising
          books
          corporate
          infographic
          personal
          press
        }
        cat_styles {
          flat
          gradients
          isometric
          linear
          low_poly
        }
        thumbnail
        p1
        p2
        color
        credits
      }
    }
  }
`;
